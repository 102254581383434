import { Redirect } from 'react-router-dom';
export default function Login(props) {
    const { isAuthenticated, loginWithRedirect, isLoading } = props;
    if (isAuthenticated && !isLoading) {
        return (<Redirect to="/exercise" />);
    }
    return (<main>
        {(isAuthenticated === false && isLoading === false) &&
            <script>
                {(() => {
                    loginWithRedirect();
                })()}
            </script>
        }
    </main>);
}
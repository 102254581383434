const toPascalCase = (words) => {
    return words.split(' ').map(w => {
        return `${w.charAt(0).toUpperCase()}${w.substr(1).toLowerCase()}`
    }).join(' ')
    // return words.match(/-* *[a-z]*[0-9]*('s)*/gi)
    //     .map(function (word) {
    //         return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    //     })
    //     .join('')
}

export default toPascalCase;
import React, { useEffect } from 'react';
import Loading from '../components/Loading';
import useApi from '../components/hooks/useApi';
import { Link } from 'react-router-dom';
import useDate from '../components/hooks/useDate';

function WorkoutLog(props) {
    const { unitPreference, getAccessTokenSilently } = props;
    const { data: workoutHistory, loading: workoutApiLoading, getMyWorkouts, error } = useApi({ shouldCall: false, getToken: getAccessTokenSilently });
    const { toLocalDate } = useDate();
    const verifyWorkouts = () => {
        return workoutHistory !== null && workoutHistory.length > 0
    }
    const UnitSystem = {
        Imperial: 0,
        Metric: 1
    }

    const units = {
        mass: unitPreference === UnitSystem.Imperial ? 'lb' : 'kg',
        distance: unitPreference === UnitSystem.Imperial ? 'mi' : 'km'
    }
    useEffect(() => {
        document.title = "Exercise History - Undou";
        getMyWorkouts();
    }, []);

    return <> {workoutApiLoading
        ? <Loading message="Loading your workouts" />
        : <main id='workoutlog-main' className="grid">
            <header className='grid-col-span-2'>
                <h1>Workout History</h1>
            </header>
            {verifyWorkouts() && workoutHistory.map((e) => {
                return <section className="card bordered" key={e.id} id={e.id}>
                    <h2>{toLocalDate(e.dateEnd)}</h2>
                    <p>Volume: {e.totalVolume}{units.mass}</p>
                    <p>Distance: {e.totalDistance}{units.distance}</p>
                    <Link className="link link-underline" to={`/workoutlog/${e.id}`}>View Workout Details</Link>
                </section>
            })}
            
            {error === 'No workouts were found.' &&
                <h1 className='center-text'><Link className="link link-underline" to="/workout">You don't have any workouts! Let's create one.</Link></h1>
            }
        </main>
    }
    </>
}

export default WorkoutLog;
import React, { useEffect, useState, useReducer } from 'react';
import Loading from '../components/Loading';
import WorkoutForm from '../components/WorkoutForm';
import WorkoutItem from '../components/WorkoutItem';
import Prompt from '../components/Prompt';
import useApi from '../components/hooks/useApi';
import createGuid from '../components/functions/uuid';
import localStorageApi from '../components/hooks/localStorageApi.js';
import useDate from '../components/hooks/useDate.js';
import data from '../data/exercises.json';

function Workout(props) {
    const unitValues = ["lb", "mi", "yd", "kg", "km", "m"];
    const UnitSystem = {
        Imperial: 0,
        Metric: 1
    }
    const Units = {
        Pounds: 0,
        Miles: 1,
        Yards: 2,
        Kilograms: 3,
        Kilometers: 4,
        Meters: 5,
    }
    const { unitPreference, getAccessTokenSilently } = props;
    const [currentWorkout, setCurrentWorkout] = useState({
        name: '',
        repetitions: 1,
        value: 0,
        unit: unitPreference === UnitSystem.Imperial ? Units.Pounds : Units.Kilograms,
        dateStart: null,
        dateEnd: null,
        unitType: unitPreference
    });
    const { getDate } = useDate();
    const initialWorkoutState = { dateStart: getDate(), current: [] };
    const [searchResults, setSearchResults] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [showStartedWorkouts, setShowStartedWorkouts] = useState(true);
    const [showWorkouts, setShowWorkouts] = useState(true);
    const [showFinishedWorkouts, setShowFinishedWorkouts] = useState(true);
    const [prompt, setPrompt] = useState();
    const { loading: workoutApiLoading, postWorkout } = useApi({ shouldCall: false, getToken: getAccessTokenSilently });
    const [workouts, dispatch] = useReducer(reducer, initialWorkoutState);
    const [workoutsToStart, setWorkoutsToStart] = useState([]);
    const [startedWorkouts, setStartedWorkouts] = useState([]);
    const [completedWorkouts, setCompletedWorkouts] = useState([]);


    const imperialUnits = <>
        <option value={Units.Pounds}>Pounds</option>
        <option value={Units.Miles}>Miles</option>
    </>

    const metricUnits = <>
        <option value={Units.Kilograms}>Kilograms</option>
        <option value={Units.Kilometers}>Kilometers</option>
    </>
    function reducer(state, action) {
        switch (action.type) {
            case 'submit': return (() => {
                const { finalizedWorkout } = action.payload;
                postWorkout(finalizedWorkout);
                localStorageApi.setItem('workout', JSON.stringify(initialWorkoutState));
                return initialWorkoutState;
            })();
            case 'updateWorkouts': return (() => {
                const { workout = null, shouldUpdate = false } = action.payload;
                const formIntegrity = (shouldUpdate && workout) ? validateWorkout(workout) : validateWorkout(currentWorkout);
                if (formIntegrity.isValid) {
                    const newWorkouts = shouldUpdate ? state.current.filter(w => w.id !== workout.id) : state.current.slice();
                    if (shouldUpdate) {
                        newWorkouts.push(workout);
                    }
                    else {
                        const workout = { ...currentWorkout, id: createGuid() };
                        newWorkouts.push(workout);
                    }

                    if (state.dateStart === null) {
                        const dateStart = getDate();
                        const newState = { ...state, dateStart: dateStart, current: newWorkouts };
                        localStorageApi.setItem('workout', JSON.stringify(newState));
                        return newState;
                    }
                    else {
                        const newState = { ...state, current: newWorkouts };
                        localStorageApi.setItem('workout', JSON.stringify(newState));
                        return newState;
                    }

                }
                else {
                    setConfirmDialog(true);
                    setPrompt(<Prompt
                        formConfig={{
                            id: 'error-form',
                            onSubmit: (e) => {
                                e.preventDefault();
                                setConfirmDialog(false);
                            }
                        }}
                        confirmBtnText="Okay"
                        title="Invalid workout"
                        message={formIntegrity.message}
                    />);

                    return state;
                }
            })();
            case 'updateCurrentWorkout': return (() => {
                const { name, value, type } = action.payload;
                switch (name) {
                    case 'unit':
                        if (value === Units.Pounds || value === Units.Kilograms) {
                            setCurrentWorkout(prev => { return { ...prev, [name]: parseInt(value), unitType: 0 } });
                        }
                        else {
                            setCurrentWorkout(prev => { return { ...prev, [name]: parseInt(value), unitType: 1 } });
                        }
                        break;
                    case 'repetitions':
                        setCurrentWorkout(prev => { return { ...prev, [name]: parseInt(value) } });
                        break;
                    case 'value':
                        setCurrentWorkout(prev => { return { ...prev, [name]: parseFloat(value) } });
                        break;
                    case 'name':
                        if (type !== 'text') {
                            const results = data.exercises.filter(r => {
                                return r.Name.toUpperCase().includes(value.toUpperCase());
                            });
                            if (results !== null && results !== undefined) {
                                if (results.length > 10) {
                                    const limitedResults = results.splice(0, 10);
                                    setSearchResults(limitedResults);
                                }
                                else {
                                    setSearchResults(results);
                                }
                            }
                            else {
                                setSearchResults(null);
                            }

                            if (value === '') {
                                setSearchResults(null);
                            }
                        }
                        setCurrentWorkout(prev => { return { ...prev, [name]: value } });
                        break;
                    default:
                        setCurrentWorkout(prev => { return { ...prev, [name]: value } });
                        break;
                }
                return state;
            })();
            case 'delete': return (() => {
                const { workoutId } = action.payload;
                const newWorkouts = state.current.filter(w => w.id !== workoutId);
                localStorageApi.setItem('workout', JSON.stringify({ current: newWorkouts }));
                return { ...state, current: newWorkouts };
            })();
            case 'initialize': return (() => {
                return action.payload;
            })();
            case 'reset': return (() => {
                localStorageApi.setItem('workout', JSON.stringify(initialWorkoutState));
                return initialWorkoutState;
            })();
            default:
                return state;
        }
    }
    function validateWorkout(workout) {
        const { repetitions = 0, value = 0, name = null, } = workout;
        const isValid = name !== '' && name !== null && name !== undefined && repetitions > 0 && value >= 0;
        const messages = [];
        if (parseInt(repetitions) < 1 || isNaN(repetitions)) {
            messages.push("At least 1 Rep required");
        }
        if (parseFloat(value) < 0 || isNaN(value)) {
            messages.push(`${name} value cannot be less than 0`);
        }
        if (name === '' || name === null || name === undefined) {
            messages.push("Exercise must have a name.");
        }

        const message = messages.length > 1 ? messages.join('. ') : messages[0];
        return { isValid, message };
    }

    function handleSubmit(e, workout = null) {
        e.preventDefault();
        const { id } = e.target;
        const shouldUpdate = workout !== null ? true : false;
        if (id === "create-workout-form" || shouldUpdate) {
            dispatch({ type: 'updateWorkouts', payload: { workout, shouldUpdate } });
        }

        if (id === "finish-workout-form") {
            const filteredWorkouts = workouts.current.filter(w => (w.dateStart === null || w.dateEnd === null));
            if (filteredWorkouts.length > 0) {
                setConfirmDialog(true);
                setPrompt(<Prompt
                    formConfig={{
                        id: 'error-form',
                        onSubmit: (e) => {
                            e.preventDefault();
                            setConfirmDialog(false);
                        }
                    }}
                    confirmBtnText="Okay"
                    title="Invalid workouts"
                    message="You still have some workouts that you haven't started" />);
            }
            else {
                setConfirmDialog(true);
                setPrompt(<Prompt
                    formConfig={{
                        id: 'confirm-workout-form',
                        onSubmit: e => handleSubmit(e, null)
                    }}
                    confirmBtnText="Finish Workout"
                    showCancelBtn={true}
                    cancelCallBack={() => { setConfirmDialog(false); }}
                    title="Finish Workout"
                    message="Are you sure you want to finish the workout?"
                />);
            }
        }

        if (id === "confirm-workout-form") {
            setConfirmDialog(false);

            const finalizedWorkout = {
                dateStart: workouts.dateStart,
                dateEnd: getDate(),
                workoutSets: workouts.current,
            }
            dispatch({ type: 'submit', payload: { finalizedWorkout } });
        }
    }

    function handleClick(e) {
        const { id } = e.target.localName === 'span' ? e.target.parentNode : e.target;
        setCurrentWorkout(prev => {
            return { ...prev, 'name': id.trim() };
        });
        setSearchResults(null);
        document.getElementById('exercise-input').value = id.trim();
    }
    const verifyWorkouts = () => {
        return (workouts.current !== null && workouts.current.length > 0);
    }

    useEffect(() => {
        if (workouts.current.length > 0) {
            setWorkoutsToStart(workouts.current.filter(w => w.dateStart === null && w.dateEnd === null));
            setStartedWorkouts(workouts.current.filter(w => w.dateStart !== null && w.dateEnd === null));
            setCompletedWorkouts(workouts.current.filter(w => w.dateStart !== null && w.dateEnd !== null));
        }
    }, [workouts]);

    useEffect(() => {
        document.title = "Exercise - Undou";

        const workoutData = localStorageApi.getItem('workout');
        if (workoutData !== null && workoutData !== undefined) {
            dispatch({ type: 'initialize', payload: JSON.parse(workoutData) })
        } else {
            dispatch({ type: 'reset' });
        }
    }, []);

    return <>
        {workoutApiLoading && <Loading message="Saving your workout" />}
        <main className="grid">
            <div className='workout-wrapper'>
                <WorkoutForm
                    currentWorkout={currentWorkout}
                    dispatch={dispatch}
                    handleClick={handleClick}
                    handleSubmit={handleSubmit}
                    searchResults={searchResults}
                    unitPreference={unitPreference}
                    unitValues={unitValues}
                    Units={Units}
                    UnitSystem={UnitSystem}
                    imperialUnits={imperialUnits}
                    metricUnits={metricUnits}
                />
                {verifyWorkouts() && <>
                    <form id="finish-workout-form" onSubmit={e => handleSubmit(e, null)} className="grid centered container">
                        <button className="button btn-confirm med width-50 shadow">Finish Workout</button>
                    </form>
                </>
                }
            </div>

            {verifyWorkouts() && <>
                <article className="workout grid place-self-start">
                    <section id='started-workouts' className={startedWorkouts.length > 0 ? `workout-section ${!showStartedWorkouts && 'hidden-workouts'} container bordered shadow` : ''}>
                        {startedWorkouts.length > 0 && <>
                            <h2 onClick={() => setShowStartedWorkouts(p => !p)} className={!showStartedWorkouts && 'hidden-workouts'} >Workouts in progress</h2>
                            {startedWorkouts.map((w) => {
                                return <WorkoutItem key={w.id} workout={w} dispatch={dispatch} unitValues={unitValues} validateWorkout={validateWorkout} setPrompt={setPrompt} setConfirmDialog={setConfirmDialog} />
                            })}
                        </>
                        }
                    </section>
                    {workoutsToStart.length > 0 && <section className={`workout-section ${!showWorkouts && 'hidden-workouts'} container bordered shadow`}>
                        <h2 onClick={() => setShowWorkouts(p => !p)} className={!showWorkouts && 'hidden-workouts'} >Workouts not started</h2>
                        {workoutsToStart.map((w) => {
                            return <WorkoutItem key={w.id} workout={w} dispatch={dispatch} unitValues={unitValues} validateWorkout={validateWorkout} setPrompt={setPrompt} setConfirmDialog={setConfirmDialog} />
                        })}
                    </section>
                    }
                    {completedWorkouts.length > 0 && <section className={`workout-section ${!showFinishedWorkouts && 'hidden-workouts'} container bordered shadow`}>
                        <h2 onClick={() => setShowFinishedWorkouts(p => !p)} className={!showFinishedWorkouts && 'hidden-workouts'} >Finished workouts</h2>
                        {completedWorkouts.map((w) => {
                            return <WorkoutItem key={w.id} workout={w} dispatch={dispatch} unitValues={unitValues} validateWorkout={validateWorkout} setPrompt={setPrompt} setConfirmDialog={setConfirmDialog} />
                        })}
                    </section>
                    }
                </article>
            </>}
            {confirmDialog && prompt}
        </main>
    </>
}

export default Workout;
import { useState } from "react";
import FormInput from "./FormInput";
import useDate from './hooks/useDate';
import Prompt from './Prompt';
function WorkoutItem(props) {
    const { workout: currentWorkout, dispatch, unitValues, validateWorkout, setConfirmDialog, setPrompt } = props;
    const [editing, setEditing] = useState(false);
    const [workout, setWorkout] = useState(currentWorkout);
    const hasStarted = workout.dateStart !== null
    const hasEnded = workout.dateEnd !== null
    const total = parseFloat(workout.repetitions) * parseFloat(workout.value);
    const { getDate } = useDate();

    const handleChange = (e) => {
        const { name } = e.target;

        if (name === 'edit') {
            setEditing(true);
        }
    }

    const handleUpdate = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'value':
                setWorkout(prev => { return { ...prev, [name]: parseFloat(value) } });
                break;
            case 'repetitions':
            case 'unit':
                setWorkout(prev => { return { ...prev, [name]: parseInt(value) } });
                break;
            case 'dateStart':
            case 'dateEnd':
                setWorkout(prev => {
                    const newWorkout = { ...prev, [name]: getDate() };
                    dispatch({
                        type: 'updateWorkouts',
                        payload: {
                            workout: newWorkout,
                            shouldUpdate: true
                        }
                    });

                    if (name === 'dateStart') {
                        document.getElementById('started-workouts').scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
                    }
                    return newWorkout;
                });
                break;
            default:
                setWorkout(prev => { return { ...prev, [name]: value } });
                break;
        }
    };

    const handleSubmit = () => {
        const formIntegrity = validateWorkout(workout);
        if (!formIntegrity.isValid) {
            setConfirmDialog(true);
            setPrompt(<Prompt
                formConfig={{
                    id: 'error-form',
                    onSubmit: (e) => {
                        e.preventDefault();
                        setConfirmDialog(false);
                    }
                }}
                confirmBtnText="Okay"
                title="Invalid workout"
                message={formIntegrity.message}
            />);
        }
        else {
            dispatch({
                type: 'updateWorkouts',
                payload: {
                    workout,
                    shouldUpdate: true
                }
            });
            setEditing(false);
        }
    }

    return <>
        <section id={`update-workout-${workout.id}`} className="workout-item grid container bordered shadow">
            <h2>{workout.name}</h2>
            {editing ? <>
                <FormInput text={`Enter (${unitValues[workout.unit]})`}
                    config={{
                        type: 'number',
                        id: `value-${workout.id}`,
                        placeholder: `Enter (${unitValues[workout.unit]})`,
                        name: 'value',
                        onChange: handleUpdate,
                        value: parseFloat(workout.value)
                    }} />
                <FormInput text="Repetitions"
                    config={{
                        type: 'number',
                        id: `reps-${workout.id}`,
                        placeholder: 'Repetitions',
                        name: 'repetitions',
                        onChange: handleUpdate,
                        value: parseInt(workout.repetitions)
                    }} />
            </>
                : <p>{workout.repetitions} Reps @ {workout.value}{unitValues[workout.unit]}</p>
            }
            <p>Total: {isNaN(total) ? '0' : total}{unitValues[workout.unit]}</p>
            {editing
                ? <button id="update-workout-from-item" className="edit-btn" name="save" onClick={handleSubmit} >Save</button>
                : <button className="edit-btn" name="edit" onClick={handleChange} >Edit</button>
            }

            {editing &&
                <button className="button med btn-warning delete-btn center-self width-50" onClick={() => { dispatch({ type: 'delete', payload: { workoutId: workout.id } }) }}>Delete</button>
            }

            {(!editing && !hasStarted) &&
                <button name="dateStart" onClick={handleUpdate} className="button med btn-confirm center-self width-50 shadow">Start</button>
            }

            {(!editing && hasStarted && !hasEnded) &&
                <button name="dateEnd" onClick={handleUpdate} className="button med btn-confirm center-self width-50 shadow">Finish</button>
            }
        </section>
    </>
}
export default WorkoutItem;
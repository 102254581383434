const useDate = () => {
    const dateFuncs = {

        getDate: () => {
            return new Date(Date.now()).toJSON();
        },

        toLocalDate: (date) => {
            return new Date(`${date}Z`).toLocaleString();
        }
    }

    return { ...dateFuncs };
}

export default useDate;

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import useWindowDimension from './components/hooks/useWindowDimension';
import Loading from './components/Loading';
import { useAuth0 } from "@auth0/auth0-react";
import UnauthenticatedApp from './UnauthenticatedApp';
import AuthenticatedApp from './AuthenticatedApp';

function App() {
	const {
		isLoading,
		error,
		user,
		isAuthenticated,
		loginWithRedirect,
		logout,
		getAccessTokenSilently
	} = useAuth0();

	const { width } = useWindowDimension();
	const isMobileWidth = () => width < 769;

	return <>
		<Router>
			{isLoading
				? (<Loading caller="App" message="Loading..." />)
				: <>
					{isAuthenticated
						? <AuthenticatedApp
							isLoading={isLoading}
							error={error}
							user={user}
							isAuthenticated={isAuthenticated}
							loginWithRedirect={loginWithRedirect}
							logout={logout}
							getAccessTokenSilently={getAccessTokenSilently}
							isMobileWidth={isMobileWidth} />
						: <UnauthenticatedApp isAuthenticated={isAuthenticated} loginWithRedirect={loginWithRedirect} isMobileWidth={isMobileWidth} />
					}
				</>
			}
		</Router>
	</>
}

export default App;
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../components/hooks/useApi';
import useDate from '../components/hooks/useDate';
import Loading from '../components/Loading';
function WorkoutLogItem(props) {
	const { id } = useParams();
	const { unitPreference, getAccessTokenSilently } = props;
	const { data: workout, loading: workoutApiLoading, getWorkoutDetails } = useApi({ shouldCall: false, getToken: getAccessTokenSilently });
	const { toLocalDate } = useDate();

	const verifyWorkout = () => {
		return workout !== null && workout !== undefined && workout.workoutSets.length > 0;
	}
	const UnitSystem = {
		Imperial: 0,
		Metric: 1
	}

	const UnitType = {
		Mass: 0,
		Distance: 1
	}
	const units = {
		mass: unitPreference === UnitSystem.Imperial ? 'lb' : 'kg',
		distance: unitPreference === UnitSystem.Imperial ? 'mi' : 'km'
	}
	const sortByName = (first, second) => {
		const a = first.name.toUpperCase();
		const b = second.name.toUpperCase();

		if (a < b) {
			return -1;
		}

		if (a > b) {
			return 1;
		}
		return 0;
	};

	useEffect(() => {
		document.title = "History - Exercise Logger"
		getWorkoutDetails(id);
	}, []);

	return <>{workoutApiLoading
		? <Loading message="Workout loading" />
		: <main className="grid">
			{verifyWorkout() && <>
				<header className='grid-col-span-2'>
					<h1>Exercise Summary</h1>
					<p>{toLocalDate(workout.dateEnd)}</p>
					<p>{workout.workoutSets.length} Exercises Completed</p>
					<p>Total Volume: {`${workout.totalVolume}${units.mass}`}</p>
					<p>Total Distance: {`${workout.totalDistance}${units.distance}`}</p>
				</header>
				{workout.workoutSets.sort(sortByName).map((e) => {
					return <section key={e.id} className="card bordered">
						<h2>{e.name}</h2>
						{e.unitType === UnitType.Mass
							? <p>{e.repetitions} repetitions @ {e.value}{units.mass}</p>
							: <p>{e.repetitions} repetitions @ {e.value}{units.distance}</p>
						}
					</section>
				})}
			</>}
		</main>
	}
	</>
}

export default WorkoutLogItem;
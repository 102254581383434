const localStorageApi = {
    getItem: (key) => {
        const item = localStorage.getItem(key);
        return item;
    },

    setItem: (key, value) => {
        localStorage.setItem(key, value);
    },

    deleteItem: (key) => {
        localStorage.removeItem(key);
    }
    //return [getItem, setItem, deleteItem];
}

export default localStorageApi;
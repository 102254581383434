function Prompt(props) {
    function cancel(e) {
        e.preventDefault();
        props.cancelCallBack();
    }
    return <section role="dialog" aria-labelledby="dialogTitle" aria-describedby="dialogDesc" className="dialog-container">
        <div className="dialog">
            <h2 id="dialogTitle">{props.title}</h2>
            <p id="dialogDesc">{props.message}</p>
            <form className="dialog-form" {...props.formConfig}>

                {props.showCancelBtn &&
                    <button onClick={cancel} className="button btn-warning med">Cancel</button>
                }
                <button className="button btn-confirm med">{props.confirmBtnText}</button>
            </form>
        </div>
    </section>
}

export default Prompt;
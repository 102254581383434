import LogoutButton from "../components/auth/LogoutButton";
import Loading from '../components/Loading'
import { useState, useEffect } from "react";

function Profile(props) {

    const { user, logout, userSettings, apiLoading, updateProfile } = props;
    const [settings, setSettings] = useState(userSettings);
    const [message, setMessage] = useState('Loading profile');
    function handleSubmit(e) {
        e.preventDefault();
        updateProfile(settings);
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setMessage('Updating profile');
        if (name !== 'displayName') {
            setSettings(prev => { return { ...prev, [name]: parseInt(value) }; });
        }
        else {
            setSettings(prev => { return { ...prev, [name]: value }; });
        }
    }

    useEffect(() => { document.title = "Profile - Undou"; }, []);
    useEffect(() => {
        setSettings(userSettings);
    }, [userSettings]);

    return <>
        {(apiLoading || !settings || typeof settings !== 'object') ? <Loading message={message} caller="Profile" /> :
            <main id="profile-main" className="grid footer-margin">
                <section className="grid centered">
                    <h1>Profile</h1>
                    <img className="profile-pic" src={user.picture} alt={user.name} />
                    <aside className="center-text">
                        <small className="display-block">Unique Id</small>
                        <small className="display-block">{settings.id}</small>
                    </aside>
                </section>
                <form id="profile-prefs-form" className="grid container bordered shadow" onSubmit={handleSubmit}>
                    <h1>Preferences</h1>
                    <section>
                        <label htmlFor="display-name">Display Name</label>
                        <input type="text" id="display-name" className="display-block input width-100" value={settings.displayName} name="displayName" placeholder="Enter your display name" onChange={handleChange}></input>
                    </section>

                    <section>
                        <label htmlFor="unit-pref">Unit Preference</label>
                        <select id="unit-pref" className="display-block input width-100" name="unitPreference" value={settings.unitPreference} onChange={handleChange}>
                            <option value={0}>Imperial</option>
                            <option value={1}>Metric</option>
                        </select>
                    </section>

                    <section>
                        <label htmlFor="profile-vis">Profile Visibility</label>
                        <select id="profile-vis" className="display-block input width-100" name="profileVisibility" value={settings.profileVisibility} onChange={handleChange}>
                            <option value={0}>Followers</option>
                            <option value={1}>Private</option>
                            <option value={2}>Public</option>
                        </select>
                    </section>
                    <button className="button btn-confirm med width-50 center-self">Save Changes</button>
                </form>
                <LogoutButton logout={logout} />
            </main>
        }
    </>
}

export default Profile;
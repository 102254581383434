import FormInput from './FormInput';
import toPascalCase from '../components/functions/toPascalCase';
export default function WorkoutForm(props) {
    const { handleSubmit,
        handleClick,
        searchResults,
        unitPreference,
        unitValues,
        dispatch,
        Units,
        UnitSystem,
        imperialUnits,
        metricUnits
    } = props;
    const { unit } = props.currentWorkout;

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        dispatch({ type: 'updateCurrentWorkout', payload: { name, value, type } });
    }

    return <>
        <form id="create-workout-form" className="grid container bordered shadow" onSubmit={e => handleSubmit(e, null)}>
            <h1 className="center-self">Add Set</h1>

            <select id="unit-select" value={parseInt(unit)} name="unit" onChange={handleChange} className="display-block input width-100" >
                {unitPreference === UnitSystem.Imperial ? imperialUnits : metricUnits}
            </select>

            {(unit === Units.Pounds || unit === Units.Kilograms)
                ? <FormInput text="Exercise"
                    config={{
                        type: 'search',
                        id: 'exercise-input',
                        placeholder: 'Search Exercise',
                        name: 'name',
                        onChange: handleChange
                    }} >
                    {searchResults !== null && <ul className="search-results">
                        {searchResults.map((r, i) => {
                            return <li
                                onClick={handleClick}
                                key={i}
                                id={toPascalCase(r.Name)}
                                className="search-result">{toPascalCase(r.Name)} <span>{r.Muscle ? toPascalCase(r.Muscle) : ''}</span>
                            </li>
                        })}
                    </ul>
                    }
                </FormInput>
                : <FormInput text="Exercise"
                    config={{
                        type: 'text',
                        id: 'exercise-input',
                        placeholder: 'Search Exercise',
                        name: 'name',
                        onChange: handleChange
                    }} />}

            <FormInput text={`Enter (${unitValues[unit]})`}
                config={{
                    type: 'number',
                    id: 'value-input',
                    placeholder: `Enter (${unitValues[unit]})`,
                    name: 'value',
                    step: '0.01',
                    onChange: handleChange
                }} />

            <FormInput text="Repetitions"
                config={{
                    type: 'number',
                    id: 'repetition-input',
                    placeholder: 'Repetitions',
                    name: 'repetitions',
                    onChange: handleChange
                }} />
            <button className="button btn-confirm med width-50 center-self shadow">Add Set</button>
        </form>
    </>
}
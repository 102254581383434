import { useState, useEffect } from 'react';
import config from '../../data/auth_config.json';
import routes from '../../data/routes.json'
const useApi = (props) => {
    const { getToken } = props;
    const [state, setState] = useState({
        error: null,
        loading: props.shouldCall,
        data: null,
        url: props.route ? `${config.api}/${props.route}` : null,
        fetchOptions: props.fetchOptions ? props.fetchOptions : {},
        shouldCall: props.shouldCall,
        audience: config.audience,
        scope: config.scope,
        api: config.api
    });

    useEffect(() => {
        if (state.url !== null && state.shouldCall) {
            (async () => {
                try {
                    const accessToken = await getToken({ audience: state.audience, scope: state.scope });
                    const res = await fetch(state.url, {
                        ...state?.fetchOptions,
                        headers: {
                            ...state?.fetchOptions?.headers,
                            'Authorization': `Bearer ${accessToken}`,
                            'Accept': '*/*'
                        },
                    });

                    const resData = await res.json();

                    if (resData.errorMessage !== undefined) {
                        setState(prev => { return { ...prev, data: null, error: resData.errorMessage, loading: false, shouldCall: false } });
                    }
                    else {
                        setState(prev => { return { ...prev, data: resData, error: null, loading: false, shouldCall: false } });
                    }
                }
                catch (error) {
                    setState(prev => { return { ...prev, data: null, error, loading: false, shouldCall: false } });
                }
            })();
        }
        else if (!state.shouldCall && state.loading) {
            setState(prev => { return { ...prev, loading: false } });
        }
    }, [state, getToken]);

    const apiFunctions = {
        postWorkout: (workout) => {
            setState(prev => {
                return {
                    ...prev,
                    loading: true,
                    shouldCall: true,
                    url: `${state.api}/${routes.Workouts}`,
                    fetchOptions: { body: JSON.stringify(workout), method: 'POST', headers: { 'Content-Type': 'application/json' } }
                }
            });
        },
        getMyWorkouts: () => {
            setState(prev => {
                return {
                    ...prev,
                    loading: true,
                    shouldCall: true,
                    url: `${state.api}/${routes.Workouts}`,
                    fetchOptions: {}
                }
            });
        },
        getWorkoutDetails: (id) => {
            setState(prev => {
                return {
                    ...prev,
                    loading: true,
                    shouldCall: true,
                    url: `${state.api}/${routes.Workouts}/${id}`,
                    fetchOptions: {}
                }
            });
        },
        getMyProfile: () => {
            setState(prev => {
                return {
                    ...prev,
                    loading: true,
                    shouldCall: true,
                    url: `${state.api}/${routes.Me}`,
                    fetchOptions: {}
                }
            });
        },
        getUser: (query) => {
            setState(prev => {
                return {
                    ...prev,
                    loading: true,
                    shouldCall: true,
                    url: `${state.api}/${routes.Users}?${query}`,
                    fetchOptions: {}
                }
            });
        },
        updateProfile: (user) => {
            setState(prev => {
                return {
                    ...prev,
                    loading: true,
                    shouldCall: true,
                    url: `${state.api}/${routes.Users}`,
                    fetchOptions: { body: JSON.stringify(user), method: 'PUT', headers: { 'Content-Type': 'application/json' } }
                }
            });
        },

        createProfile: () => {
            setState(prev => {
                return {
                    ...prev,
                    loading: true,
                    shouldCall: true,
                    url: `${state.api}/${routes.Users}`,
                    fetchOptions: { method: 'POST', headers: { 'Content-Type': 'application/json' } }
                }
            });
        },

        refresh: () => {
            setState(prev => {
                return {
                    ...prev,
                    shouldCall: true
                }
            });
        }
    }
    return {
        ...state,
        ...apiFunctions
    };
};

export default useApi;
import { useState, useEffect, useRef } from "react";

export default function Loading(props) {
    const spanOne = useRef();
    const spanTwo = useRef();
    const spanThree = useRef();
    const [active, setActive] = useState(spanOne);
    const timeOut = 350;
    useEffect(() => {
        if (active.current.id === '1') {
            spanOne.current.classList.toggle("active");
            spanTwo.current.classList.add("active");
            setTimeout(() => {
                setActive(spanTwo);
            }, timeOut);
        }
        if (active.current.id === '2') {
            spanTwo.current.classList.toggle("active");
            spanThree.current.classList.add("active");
            setTimeout(() => {
                setActive(spanThree);
            }, timeOut);
        }
        if (active.current.id === '3') {
            spanThree.current.classList.toggle("active");
            spanOne.current.classList.add("active");
            setTimeout(() => {
                setActive(spanOne);
            }, timeOut);
        }
    }, [active]);

    const testing = false;
    const troubleShootingInfo = (<div>
        <p>Calling component: {props.caller}</p>
        <p>apiLoading: {props.apiLoading}</p>
        <p>authLoading: {props.authLoading}</p>
        <p>isAuthenticated: {props.isAuthenticated}</p>
        <p>error: {props.error}</p>
        <p>user: {props.user}</p>
    </div>);

    return <section className="jst-cont-center align-cont-center jst-item-center align-item-center loading-container">
        <h1>Undou</h1>
        <p>{props.message}</p>
        <div>
            <span id="1" ref={spanOne} className="active loading"></span>
            <span id="2" ref={spanTwo} className="loading"></span>
            <span id="3" ref={spanThree} className="loading"></span>
        </div>
        {testing && troubleShootingInfo}
    </section>;
}
import Footer from './components/Footer';
import Nav from './components/Navigation';
import LoginButton from './components/auth/LoginButton';
import mobileHomePage from './content/mobile-home-page.png';
import mobileWorkoutPage from './content/mobile-workouts.png';
import mobileCalcPage from './content/mobile-max-effort.png';
function UnauthenticatedApp(props) {
    const { loginWithRedirect, isAuthenticated, isMobileWidth } = props;

    return <>
        <Nav loginWithRedirect={loginWithRedirect} isAuthenticated={isAuthenticated} isMobileWidth={isMobileWidth} />
        <main id='landing-main' className='grid col-1 jst-item-center'>
            <header id='landing-header'>
                <h1>To undo, you must Undou...</h1>
                <aside>
                    <small><em>Undou can mean "to exercise" in Japanese, get it?</em></small>
                </aside>
            </header>
            <section>
                <LoginButton loginWithRedirect={loginWithRedirect} />
            </section>
            <article className='grid col-1 center-text'>
                <section className='grid col-1 jst-item-center rotated-background'>
                    <h2>Be in control of your health</h2>
                    <img className='width-50 shadow phone-img' alt='mobile home page' src={mobileHomePage}></img>
                </section>
                <section className='grid col-1 jst-item-center'>
                    <h2>Create custom workouts tailored to your needs</h2>
                    <img className='width-50 shadow phone-img' alt='mobile workout page' src={mobileWorkoutPage}></img>
                </section>
                <section className='grid col-1 jst-item-center rotated-background'>
                    <h2>Determine your maximum effort!</h2>
                    <img className='width-50 shadow phone-img' alt='mobile max effort calculator page' src={mobileCalcPage}></img>
                </section>
                <section className='grid col-1 jst-item-center'>
                    <h2>More features coming soon:</h2>
                    <ul>
                        <li>Food logging</li>
                        <li>Goal setting</li>
                        <li>Analytics</li>
                        <li>And more!</li>
                    </ul>
                </section>
            </article>
        </main>
        <div>
            <Footer />
        </div>
    </>
}

export default UnauthenticatedApp;
import { useEffect, useState } from 'react';
import effortData from '../data/effortvalues.json';
import FormInput from '../components/FormInput';

const oneRepFunctions = {
    brzycki: (weight, reps) => {
        return (weight * (36 / (37 - reps)))
    },
    epley: (weight, reps) => {
        return (weight * (1 + .0333 * reps))
    },
    lander: (weight, reps) => {
        return ((100 * weight) / (101.3 - 2.67123 * reps))
    }
}

export default function Calculator(props) {
    const { unitPreference } = props;
    const unit = unitPreference === 0 ? 'lb' : 'kg';
    const [oneRepMax, setOneRepMax] = useState(0);
    const [showWarning, setShowWarning] = useState(false);
    const [liftObject, setLiftObject] = useState({
        weight: 0,
        reps: 0,
        effort: 'max',
        formula: oneRepFunctions.brzycki
    });

    function handleChange(e) {
        const { target } = e;
        const { name, value } = target;

        setLiftObject(prev => {
            if (name === "formula") {
                return { ...prev, [name]: oneRepFunctions[value] }
            }
            else {
                return { ...prev, [name]: value }
            }
        });

        if (target.nodeName === "BUTTON") {
            document.getElementsByName(name).forEach(element => {
                element.classList.remove("active");
            });

            target.classList.add("active");
        }


        if (name === "reps" && value > 10) {
            setShowWarning(true);
        }
        else if (name === "reps" && value < 11) {
            setShowWarning(false);
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        setOneRepMax(liftObject.formula(liftObject.weight, liftObject.reps));
    }

    function calculateEffortTable(oneRepMax) {
        const efforts = effortData.effortValues.map((r, i) => {
            const repValue = i + effortData.effortType[liftObject.effort];
            const rowStyle = i % 2 === 0 ? "alternate-row" : "";
            if (repValue < 20) {
                const modifiedEffort = effortData.effortValues[repValue];
                return <tr key={i} className={rowStyle}>
                    <td>{i + 1} ({modifiedEffort}%)</td>
                    <td>{Math.round(oneRepMax * (modifiedEffort / 100))}{unit}</td>
                </tr>
            }
            else {
                return "";
            }
        });
        return efforts;
    }

    // useEffect(() => {
    //     if (unit.changed) {
    //         if (unit.current === "kg") {
    //             setLiftObject((prev) => {
    //                 return { ...prev, weight: convertToMetric(prev.weight) }
    //             });

    //             setOneRepMax(prev => convertToMetric(prev));
    //         }

    //         else if (unit.current === "lb") {
    //             setLiftObject((prev) => {
    //                 return { ...prev, weight: convertToImperial(prev.weight) }
    //             });

    //             setOneRepMax(prev => convertToImperial(prev));
    //         }
    //     }
    // }, [unit, setOneRepMax, setLiftObject, convertToImperial, convertToMetric]);

    useEffect(() => {
        const oneRepMax = liftObject.formula(liftObject.weight, liftObject.reps);
        setOneRepMax(oneRepMax);
    }, [setOneRepMax, liftObject]);

    useEffect(() => {
        document.title = "1RM Calculator - Undou"
        const initialEffortBtn = document.getElementById("max");
        const initialFormulaBtn = document.getElementById("brzycki");

        initialFormulaBtn.classList.add("active");
        initialEffortBtn.classList.add("active");

        //? Maybe implement this??
        //? document.getElementById("weight-input").focus();
    }, []);

    return <>
        <main className="grid centered">
            <form onSubmit={handleSubmit} id="calculator-form" className="grid centered">
                <h1>Calculate 1 Rep Max</h1>
                <FormInput text={`Weight Lifted (${unit})`}
                    config={{
                        placeholder: `Weight Lifted (${unit})`,
                        onChange: handleChange,
                        name: "weight",
                        type: "number",
                        min: "0",
                        id: "weight-input"
                    }} />

                <FormInput text="Repetitions"
                    config={{
                        placeholder: "Repetitions",
                        onChange: handleChange,
                        name: "reps",
                        type: "number",
                        min: "1",
                        id: "repetition-input"
                    }} />
                {showWarning && <aside className="warning"><em>Repetitions over 10 are inaccurate.</em></aside>}

                <fieldset id="effort-section">
                    <legend>Effort</legend>
                    <button className="button btn-confirm" onClick={handleChange} value="max" name="effort" id="max">Max</button>
                    <button className="button btn-confirm" onClick={handleChange} value="veryhard" name="effort" id="very-hard">Very Hard</button>
                    <button className="button btn-confirm" onClick={handleChange} value="hard" name="effort" id="hard">Hard</button>
                    <button className="button btn-confirm" onClick={handleChange} value="medium" name="effort" id="medium">Medium</button>
                </fieldset>

                <fieldset id="formula-section">
                    <legend>Formula</legend>
                    <button className="button btn-confirm" onClick={handleChange} value="brzycki" name="formula" id="brzycki">Brzycki</button>
                    <button className="button btn-confirm" onClick={handleChange} value="epley" name="formula" id="epley">Epley</button>
                    <button className="button btn-confirm" onClick={handleChange} value="lander" name="formula" id="lander">Lander</button>
                </fieldset>
            </form>

            {
                (liftObject.reps > 0 && liftObject.weight > 0) &&
                <table id="effort-table">
                    <thead>
                        <tr>
                            <th>Reps</th>
                            <th>{effortData.effortFriendlyName[liftObject.effort]}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calculateEffortTable(oneRepMax)}
                    </tbody>
                </table>
            }
        </main>
    </>
}
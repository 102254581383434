import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ScrollToTop from './components/hooks/ScrollToTop';
import Loading from './components/Loading';
import Calculator from './pages/Calculator';
import Workout from './pages/Workout';
import WorkoutLog from './pages/WorkoutLog';
import WorkoutLogItem from './pages/WorkoutLogItem';
import Login from './pages/Login';
import ExerciseHome from './pages/ExerciseHome';
import Profile from './pages/Profile';
import Footer from './components/Footer';
import Nav from './components/Navigation';
import ProtectedRoute from './components/auth/ProtectedRoute';
import useApi from './components/hooks/useApi';
import routes from './data/routes.json';

function AuthenticatedApp(props) {
    const {
        isLoading,
        error,
        user,
        isAuthenticated,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
        isMobileWidth
    } = props;

    const {
        data: userSettings,
        loading: apiLoading,
        error: apiError,
        getMyProfile,
        createProfile,
        updateProfile
    } = useApi({ route: routes.Me, getToken: getAccessTokenSilently, shouldCall: false });

    useEffect(() => {
        if (isAuthenticated && userSettings === null && !apiLoading) {
            getMyProfile();
        }

        if (apiError && isAuthenticated && !apiLoading) {
            console.error(apiError);
            if (apiError === "User can't be found.") {
                createProfile();
            }

            if (apiError === "User was not modified.") {
                getMyProfile();
            }
        }
    }, [isAuthenticated, apiError, apiLoading, userSettings, getMyProfile, createProfile]);

    if (error || apiError) {
        console.error(`Error: ${error ? error.message : apiError}`);
    }

    return <>
        {(apiLoading || !userSettings || typeof userSettings !== 'object')
            ? (<Loading caller="App" message="Loading profile info"
                apiLoading={apiLoading ? JSON.stringify(apiLoading) : 'false'}
                isAuthenticated={isAuthenticated ? JSON.stringify(isAuthenticated) : 'false'}
                user={userSettings ? JSON.stringify(userSettings, null, 2) : 'false'}
                error={error ? JSON.stringify(error) : 'no errors'} />)
            : (<>
                <Nav isMobileWidth={isMobileWidth} user={user} loginWithRedirect={loginWithRedirect} isAuthenticated={isAuthenticated} />
                <ScrollToTop />
                <Switch>
                    <Route path="/login">
                        <Login isLoading={isLoading}
                            isAuthenticated={isAuthenticated}
                            loginWithRedirect={loginWithRedirect} />
                    </Route>

                    <Route path="/profile">
                        <ProtectedRoute isAuthenticated={isAuthenticated}
                            component={<Profile user={user}
                                logout={logout}
                                userSettings={userSettings}
                                apiLoading={apiLoading}
                                updateProfile={updateProfile}
                                isMobileWidth={isMobileWidth} />} />
                    </Route>

                    <Route path="/exercise">
                        <ProtectedRoute isAuthenticated={isAuthenticated} component={<ExerciseHome isMobileWidth={isMobileWidth} />} />
                    </Route>

                    <Route path="/calculator">
                        <ProtectedRoute isAuthenticated={isAuthenticated} component={<Calculator isMobileWidth={isMobileWidth} unitPreference={userSettings.unitPreference} />} />
                    </Route>

                    <Route path="/workout">
                        <ProtectedRoute isAuthenticated={isAuthenticated}
                            component={
                                <Workout isMobileWidth={isMobileWidth}
                                    unitPreference={userSettings.unitPreference}
                                    getAccessTokenSilently={getAccessTokenSilently}
                                />
                            } />
                    </Route>

                    <Route exact path="/workoutlog">
                        <ProtectedRoute isAuthenticated={isAuthenticated} component={<WorkoutLog getAccessTokenSilently={getAccessTokenSilently} unitPreference={userSettings.unitPreference} />} />
                    </Route>

                    <Route path="/workoutlog/:id">
                        <ProtectedRoute isAuthenticated={isAuthenticated} component={<WorkoutLogItem getAccessTokenSilently={getAccessTokenSilently} unitPreference={userSettings.unitPreference} />} />
                    </Route>

                    <Route path="/">
                        <ProtectedRoute isAuthenticated={isAuthenticated} component={<Redirect to="/exercise" />} />
                    </Route>
                </Switch>
                <div>
                    <Footer />
                </div>
            </>
            )}
    </>
}

export default AuthenticatedApp;
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


function ExerciseHome(props) {
    const { isMobileWidth } = props;
    useEffect(() => {
        document.title = "Home - Undou";
    }, []);

    return <>
        {isMobileWidth() &&
            <main id="home" className='grid col-1'>
                <Link id="create" className="img-link" to="/workout"><span>Create Workout</span></Link>
                <Link id="history" className="img-link" to="/workoutlog"><span>View History</span></Link>
                <Link id="calculate" className="img-link" to="/calculator"><span>Calculate 1 Rep Max</span></Link>
            </main>
        }
        {
            !isMobileWidth() &&
            <main id="home" className='grid col-3'>
                <section>
                    <Link id="create" className="img-link card" to="/workout"><span>Create Workout</span></Link>
                </section>
                <section>
                    <Link id="history" className="img-link card" to="/workoutlog"><span>View History</span></Link>
                </section>
                <section>
                    <Link id="calculate" className="img-link card" to="/calculator"><span>Calculate 1 Rep Max</span></Link>
                </section>
            </main>
        }
    </>
}

export default ExerciseHome;
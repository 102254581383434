import { Link } from 'react-router-dom';
import LoginButton from './auth/LoginButton';
export default function Navigation(props) {
    const { isMobileWidth, user, isAuthenticated, loginWithRedirect } = props;
    // const navWithPhotos = <nav className="mobile-nav">
    //     <ul className='col-4 grid align-cont-center'>
    //         <li>
    //             <Link to="/home">
    //                 <img className="profile-pic nav-icon" src={user.picture} alt={user.name} />
    //                 <span style={{ 'display': 'block' }}>Home</span>
    //             </Link>
    //         </li>
    //         <li>
    //             <Link to="/workout">
    //                 <img className="profile-pic nav-icon" src={user.picture} alt={user.name} />
    //                 <span style={{ 'display': 'block' }}>Exercise</span>
    //             </Link>
    //         </li>
    //         <li>
    //             <Link to="/log">
    //                 <img className="profile-pic nav-icon" src={user.picture} alt={user.name} />
    //                 <span style={{ 'display': 'block' }}>Food</span>
    //             </Link>
    //         </li>
    //         <li>
    //             <Link to="/profile">
    //                 <img className="profile-pic nav-icon" src={user.picture} alt={user.name} />
    //                 <span style={{ 'display': 'block' }}>Profile</span>
    //             </Link>
    //         </li>
    //     </ul>
    // </nav>;

    return <>
        {!isMobileWidth() && <>
            {
                isAuthenticated
                    ? <nav className="grid col-2">
                        <Link id='undou-icon' className="link-underline center-text" to="/exercise">うんどう</Link>
                        <ul className="nav-links grid col-3">
                            <li id='nav-home'><Link className="link-underline" to="/exercise">Home</Link></li>
                            <li id='nav-exercise'><Link className="link-underline" to="/workout">Exercise</Link></li>
                            <li id='nav-profile'><Link className="link-underline" to="/profile">Profile</Link></li>
                        </ul>
                    </nav>
                    : <nav className="grid unauth-nav">
                        <Link id='undou-icon' className="link-underline center-text" to="/exercise">うんどう</Link>
                        <ul id='nav-login-button' className="nav-links grid col-1 center-self">
                            <li><LoginButton loginWithRedirect={loginWithRedirect} /></li>
                        </ul>
                    </nav>
            }
        </>
        }

        {isMobileWidth() && <>
            {
                isAuthenticated
                    ? <nav className="mobile-nav grid col-2">
                        <Link id='undou-icon' className="link-underline center-text" to="/exercise">うんどう</Link>
                        <ul className='grid col-3'>
                            <li id='nav-home'><Link to="/exercise">Home</Link></li>
                            <li id='nav-exercise'><Link to="/workout">Exercise</Link></li>
                            <li id='nav-profile'><Link to="/profile">Profile</Link></li>
                        </ul>
                    </nav>
                    : <nav className="mobile-nav grid unauth-nav">
                        <Link id='undou-icon' className="link-underline center-text" to="/exercise">うんどう</Link>
                        <ul id='nav-login-button' className='grid col-1 center-self'>
                            <li><LoginButton loginWithRedirect={loginWithRedirect} /></li>
                        </ul>
                    </nav>
            }
        </>
        }
    </>
}
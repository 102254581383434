import React from 'react';

export default function FormInput(props) {
    const { text } = props;
    const { id } = props.config
    return <>
        <div className="form-input width-100">
            <input
                {...props.config}
            />
            <label
                htmlFor={id}
                className="form-label">
                {text}
            </label>
            {props.children}
        </div>
    </>
}